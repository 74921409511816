import {
  Agent,
  AgentToh,
  EditAgentInfoVariables,
  RequestedChanges,
  PendingRequests,
} from ".";
import client from "../../services/client";
import { contractRequestsByAgentId } from "../../services/GraphQl/queries";
import {
  agent,
  agentChangeRequest,
  agentToh,
  updateAgent,
} from "../../services/GraphQl/queries-new";

export interface IAgentRepository {
  load(options: LoadOptions): Promise<Agent>;
  agentChangeRequest(options: Partial<RequestedChanges>): Promise<boolean>;
  updateAgent(options: EditAgentInfoVariables): Promise<Agent>;
  agentToh(agentId: string): Promise<AgentToh>;
  pendingRequests(agentId: string): Promise<PendingRequests[]>;
}

export function AgentRepository(): IAgentRepository {
  return {
    load: async (options) => {
      const results = await client.query({
        query: agent,
        variables: {
          id: options.agentId,
        },
      });
      const languages = results.data.agent.languages?.map((data: any) => {
        return { languages: data.languages };
      });

      const finalResults = {
        ...results.data.agent,
        residentLicenseState: results.data.agent.residentState,
        languages,
      };
      return finalResults;
    },
    updateAgent: async (info) => {
      const results = await client.query({
        query: updateAgent,
        variables: info,
      });
      const languages = results.data.updateAgent.languages?.map((data: any) => {
        return { languages: data.languages };
      });
      const finalResults = { ...results.data.updateAgent, languages };
      return finalResults;
    },
    agentChangeRequest: async (options) => {
      const result = await client.query({
        query: agentChangeRequest,
        variables: { requestedChanges: options },
      });
      return result.data.agentChangeRequest;
    },
    agentToh: async (agentId) => {
      const { data } = await client.query({
        query: agentToh,
        variables: { agentId },
      });
      return data.agentToh[0];
    },
    pendingRequests: async (agentId) => {
      const { data } = await client.query({
        query: contractRequestsByAgentId,
        variables: { agentId },
      });
      return [...data.contractRequestsByAgentId];
    },
  };
} 

export interface LoadOptions {
  agentId: string;
}

export interface SubscribeOptions {
  agentId: string;
}

export type SubscriptionCallback = (agent: Agent[]) => void;
export type CancelSubscriptionCall = () => void;
