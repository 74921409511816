import { gql } from "apollo-boost";

export const fragments = {
  agentData: gql`
    fragment AgentData on Agent {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      birthDate
      description
      doNotContact
      ssn
      deleted
      status
      createDate
      lastModifiedDate
      version
      createdById
      lastModifiedById
      mssCcExpirationDate
      mssEnrollmentDate
      mssLogin
      mssStatus
      npn
      onboardDate
      originalContractedDate
      residentState
      residentLicenseNumber
      agentOwnerId
      eoExpirationDate
      marketingUnsubscribe
      reportAlias
      principalType
      mssPlanYear
      agentGroupId
      externalId
      dashboardEnabled
      languages {
        languages
      }
    }
  `,
  leadData: gql`
    fragment LeadData on Lead {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      status
      createDate
      lastModifiedDate
      description
      birthDate
      doNotContact
      ssn
      deleted
      createdById
      lastModifiedById
      agencyName
      agentDescription
      leadDate
      priority
      rating
      version
      crmOwnerId
      npn
      contractingAs
      agencyNpn
      residentState
      licenseNumber
      uplineName
      uplineWritingNumber
      notes
      prospectiveCarriers
      numberOfAgents
      timeline
      areas
      leadSourceId
      leadSourceCode
      languages {
        languages
      }
    }
  `,
  adminData: gql`
    fragment AdminData on Admin {
      id
      email
      firstName
      lastName
      status
      displayName
    }
  `,
  agentHierarchyData: gql`
    fragment AgentHierarchyData on AgentHierarchy {
      carrierId
      id
      agentId
      agentName
      level
      degreesFromBerwick
      isInactive
    }
  `,
  carrierHierarchyData: gql`
    fragment CarrierHierarchyData on CarrierHierarchy {
      id
      agentId
      carrierName
      acStatus
      isCorporation
      companyName
      taxId
      note
      carrierLabel
      agentGroupName
      overrideAgreement
      agentLevel
    }
  `,
  agentContractData: gql`
    fragment AgentContractData on AgentContract {
      id
      agentId
      carrierName
      carrierLabel
      isCorp
      contractStatus
      statusDate
      effectiveDate
      submittedToCarrier
      state
      writingNumber
      productName
    }
  `,
  noteData: gql`
    fragment NoteData on Note {
      id
      confidential
      followUpDate
      notificationDate
      priority
      recurringFollowUpInterval
      status
      createDate
      deleted
      lastModifiedDate
      timeSpent
      title
      assignedToId
      category {
        id
        name
      }
      createdById
      lastModifiedById
    }
  `,
  fileData: gql`
    fragment FileData on File {
      id
      createDate
      lastModifiedDate
      createdById
      lastModifiedById
      name
      size
      category
      isTemplate
      mimeType
    }
  `,
  noteCommentData: gql`
    fragment NoteCommentData on NoteComment {
      id
      comment
      noteId
      actor
      createDate
      lastModifiedDate
      createdById
      createdBy {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      lastModifiedById
      lastModifiedBy {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      deleted
    }
  `,
  contactData: gql`
    fragment ContactData on Contact {
      id
      address1
      address2
      address3
      city
      country
      county
      postalCode
      state
      description
      email
      faxNumber
      firstName
      lastName
      mobilePhone
      phone
      priority
      status
      createDate
      deleted
      lastModifiedDate
      type
      version
      workPhone
      individualId
      createdById
    }
  `,
  agentGroupData: gql`
    fragment AgentGroupData on AgentGroup {
      agentGroupId
      description
      name
      version
    }
  `,
  leadSourceData: gql`
    fragment LeadSourceData on LeadSource {
      leadSourceId
      name
    }
  `,
  contractRequestData: gql`
    fragment ContractRequestData on ContractRequest {
      id
      version
      leadId
      carrierId
      requestLevel
      statesRequested
      products
      directUpline
      contractingAs
      deleted
      createDate
      lastModifiedDate
      createdById
      lastModifiedById
      sentDate
    }
  `,
  awayNotificationData: gql`
    fragment AwayNotificationData on AwayNotifications {
      __typename
      id
      subject
      body
      agencyId
      agentIds {
        agentId
        status
        seen
      }
      agencyInfo {
        agencyName
      }
      createdById
      createDate
    }
  `,
};
