import React, { useState, useEffect, useReducer, forwardRef } from "react";
import HtmlTooltip from "../components/ui-components/HtmlTooltip";
import _ from "lodash";
import "../App.css";
import {
  getCarrierNameListGql,
  createContractRequest,
  getStates,
} from "../services/BDSWebService";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Zoom,
  useMediaQuery,
  Slide,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Swal from "sweetalert2";
import ValidateModal from "./ValidateModal";
import { numbersOnlyInput } from "../services/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomDatePicker from "./CustomDatePicker";
import { observer } from "mobx-react";
import { ContactStore, AgentStore } from "../store";
import CommonDialogTitle from "./CommonDialogTitle";
import useStyles from "./useStyles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const sendContractRequest = async (
  agentData,
  existingContracts,
  notes,
  agentId,
  isAsCorp,
  agentToh,
  phone,
  email
) => {
  const {
    residentState,
    licenseNumber,
    corpName,
    displayName,
    ssn,
    birthDate,
    npn,
  } = agentData;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const agentOwnerEmail = userInfo.agentOwnerEmail
    ? [userInfo.agentOwnerEmail]
    : [];
  const carriers = existingContracts.map((contract) => {
    return {
      carrierId: parseInt(contract.carrier.id),
      carrierName: contract.carrier.name,
      states: contract.statesRequested,
    };
  });
  const tohClass = agentToh?.tohClass;
  const agencyId = agentToh?.tohAgencyId;
  const requestEmail = agentToh?.tohEmail;
  const tohCc = agentToh?.tohCc;

  const agencyName = agentToh?.tohAgencies;
  const isStaging =
    sessionStorage.getItem("isStaging") === "true" ? true : false;
  var infoToInput = {
    agentId: parseInt(agentId),
    gender: agentData.sex,
    residentState,
    licenseNumber,
    isAsCorp,
    corpName,
    corpNpn: agentData.corpNPN,
    corpTin: agentData.corpTIN,
    carriers,
    agencyId: tohClass === "singel_ToH" ? agencyId : null,
    agentName: displayName,
    phone,
    email,
    npn,
    ssn,
    birthDate,
    notes,
    isStaging,
    requestEmail,
    agencyName,
    tohClass,
    agentOwnerEmail,
    tohCc,
  };

  try {
    await createContractRequest(infoToInput);
    return "success";
  } catch (err) {
    return "Failure: " + err.message;
  }
};

const ContractRequestModal = observer((props) => {
  const classes = useStyles();
  const [contactStore] = useState(() => new ContactStore());
  const [agentStore] = useState(() => new AgentStore());
  const {
    openRequestForm = true,
    agent,
    handleOpenContractRequestForm,
    agentInfoSubmitted,
    setAgentInfoSubmitted,
    updateFromContractRequest,
    agentToh,
  } = props;
  const agentId = agent?.id;
  const [openValidationModal, setOpenValidationModal] = useState(false);

  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const swalWithDialogAdjustment = Swal.mixin({
    reverseButtons: true,
    customClass: {
      "z-index": "2000 !important",
    },
  });

  const StatesRequestedTooltipText =
    "Needed in order to send proper contracting paperwork to agent. Note: Agents MUST select appropriate states on the paperwork and approval is at the discretion of the carrier.";
  const TINTooltipText = "TIN required for Anthem corp contracts";
  const GenderTooltipText = "Gender required for Florida Blue contracts";
  const ResidentStateTooltipText = "The state in which the agent resides";
  const SocialNumberTooltipText = "Required for Anthem and Florida Blue";
  const LicenseNumberTooltipText = "License # REQUIRED for SCAN contracts.";
  const NpnTooltipText = "NPN REQUIRED to proceed";

  const initialAgentData = {
    ...agent,
    corpName: "",
    corpNPN: "",
    corpTIN: "",
  };
  const [agentData, setAgentData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialAgentData
  );

  useEffect(() => {
    setAgentData(initialAgentData);
  }, [agent]);

  const initialContract = {
    carrier: null,
    statesRequested: null,
  };

  const [contract, setContract] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialContract
  );

  const [unlistedCarrier, setUnlistedCarrier] = useState(false);

  const handleAgentDataChange = (e, date, incName) => {
    // Special casing for datepicker
    const name = e?.target?.name ?? incName;
    const value = e?.target?.value ? e?.target?.value : date ? date : null;
    agentData[name] = value;
    setAgentData(agentData);
  };

  const handleContractChange = (e) => {
    const { name, value } = e.target;
    contract[name] = value;
    setContract(contract);
  };

  const unlistedCarrierChange = (e) => {
    const { name, value } = e.target;
    contract[name] =
      value.trim() === "" ? null : { id: "-999", name: value, isOther: true };
    setContract(contract);
  };
  const [agentValidationErrors, setAgentValidationErrors] = useState([]);
  const [existingContracts, setExistingContracts] = useState([]);
  const [carrierOptionsArray, setCarrierOptionsArray] = useState([]);
  const [contractValidationErrors, setContractValidationErrors] = useState([]);
  const [contractChipIndex, setContractChipIndex] = useState();
  const [corpfieldsShown, setCorpFieldsShown] = useState(false);
  const [toggleHide, setToggleHide] = useState(false);
  const [notesValue, setNotesValue] = useState();
  const [unitedStates, setUnitedStates] = useState([]);

  useEffect(() => {
    getCarrierNameListGql().then((res) => {
      setCarrierOptionsArray(res.data.getCarrierNameList);
    });
    getStates().then((res) => setUnitedStates(res.data.getStates));
    return () => {
      contactStore.disposePersist();
    };
  }, []);
  const removeContractChip = (index) => {
    let newContractArray = existingContracts;
    newContractArray.splice(index, 1);
    setExistingContracts(newContractArray);
    resetContractPanel();
  };

  const loadContract = (selectedContract) => {
    setContract(selectedContract);
  };

  const checkIsOther = (selectedContract) => {
    setUnlistedCarrier(selectedContract.carrier.isOther ? true : false);
  };

  const handleContractClicked = (index) => {
    let selectedContract = existingContracts[index];
    if (
      !_.isEqual(contract, selectedContract) &&
      !_.isEqual(contract, initialContract)
    ) {
      swalWithDialogAdjustment
        .fire({
          title: "All unsaved changes will be lost!",
          text: "Click OK to discard the current information and load this contract.",
          icon: "warning",
          showCancelButton: true,
          //TODO: update the styling since danger Mode isn't a thing anymore
          // dangerMode: true,
        })
        .then((result) => {
          if (result.value) {
            checkIsOther(selectedContract);
            loadContract(selectedContract);
            setContractChipIndex(index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            //action cancelled
          }
        });
    } else {
      checkIsOther(selectedContract);
      loadContract(selectedContract);
      setContractChipIndex(index);
    }
  };

  const handleContractDelete = async (index) => {
    if (index === contractChipIndex) {
      removeContractChip(index);
    } else {
      await swalWithDialogAdjustment
        .fire({
          title: "Are you sure you want to remove this contract?",
          text: "Press OK to confirm.",
          icon: "warning",
          showCancelButton: true,
          // dangerMode: true,
        })
        .then((deleteContract) => {
          if (deleteContract.value) {
            removeContractChip(index);
          } else if (deleteContract.dismiss === Swal.DismissReason.cancel) {
            //nothing
          }
        });
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "switch"
        ? target.checked
        : target.value;
    const name = target.name;
    if (name === "ContractingAsCorp") {
      setCorpFieldsShown(value);
      if (value === false) {
        agentData.corpNPN = undefined;
        agentData.corpName = undefined;
        setAgentData(agentData);
      }
    }
  };

  const handleNotesChange = (event) => {
    setNotesValue(event.target.value);
  };

  const clearContractModal = () => {
    setAgentData(initialAgentData);
    setExistingContracts([]);
    setContractValidationErrors([]);
    setCorpFieldsShown(false);
    setNotesValue(null);
    handleOpenContractRequestForm(false);
    resetContractPanel();
  };

  const contractIsValid = () => {
    var newValidationObj = { ...contractValidationErrors };
    if (!contract.carrier) {
      newValidationObj.carrier = "Please select a carrier for this contract.";
    } else {
      newValidationObj.carrier = undefined;
    }
    if (!contract.statesRequested) {
      newValidationObj.statesRequested =
        "Please enter states requested for this contract.";
    } else {
      newValidationObj.statesRequested = undefined;
    }
    setContractValidationErrors(newValidationObj);

    return !Object.values(newValidationObj).some((item) => item !== undefined);
  };

  const handleAddContract = async () => {
    if (contractIsValid()) {
      if (contractChipIndex !== undefined && contractChipIndex !== null) {
        handleContractDelete(contractChipIndex);
      }
      existingContracts.push(contract);
      const moreThanOneScan = existingContracts.filter(
        (contract) => contract.carrier.name === "SCAN"
      );
      if (contract.carrier.name === "SCAN" && moreThanOneScan.length === 1) {
        swalWithDialogAdjustment.fire(
          "SCAN requires license numbers for each state requested.",
          "If requesting more than 1 state (resident state) please indicate the license numbers and states in the notes section below.",
          "info"
        );
      }

      setExistingContracts(existingContracts);
      resetContractPanel();
    } else {
      swalWithDialogAdjustment.fire(
        "Oops!",
        "Please review the contract form for errors.",
        "error"
      );
    }
  };

  const resetContractPanel = () => {
    loadContract(initialContract);
    setContractChipIndex(null);
    setContractValidationErrors([]);
    setUnlistedCarrier(false);
  };

  const handleCancelSubmit = () => {
    handleOpenContractRequestForm(true);
    setOpenValidationModal(!openValidationModal);
  };

  const handleSubmit = async () => {
    let invalid = false;
    let sweetAlertMessage = "";

    if (JSON.stringify(contract) !== JSON.stringify(initialContract)) {
      invalid = true;
      let _title = "Save this contract?";
      let _text =
        "Looks like there is data in the contract area, but you didn't click 'Add Contract'.  Click OK to save this contract and submit, or cancel to continue editing.";
      setAgentInfoSubmitted(false);
      await swalWithDialogAdjustment
        .fire({
          title: _title,
          text: _text,
          icon: "warning",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            handleAddContract();
            invalid = false;
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            //nothing
          }
        });
    }

    if (existingContracts.length === 0) {
      invalid = true;
      sweetAlertMessage =
        "At least one contract is required!  Please add a contract.";
      setAgentInfoSubmitted(false);
    }
    const onlyWelbeContracts = existingContracts.every(function (_contract) {
      return _contract.carrier.name.toString().toLowerCase().includes("welbe");
    });

    if (
      !onlyWelbeContracts &&
      (agentData.npn === undefined || agentData.npn === "")
    ) {
      //NPN is required for every agent if any contract is for a carrier other than Welbe.
      //In other words, NPN is only NOT required when Welbe is the only contract carrier.
      sweetAlertMessage =
        "NPN is required unless Welbe is the only contract carrier.";
      invalid = true;
    }

    const anyWelbeContracts = existingContracts.some(function (_contract) {
      return _contract.carrier.name.toString().toLowerCase().includes("welbe");
    });

    if (anyWelbeContracts && !agentData.birthDate) {
      //Welbe requires DOB for all agents.  If a DOB is missing, alert the user.
      sweetAlertMessage = "Welbe contracts require DOB.";
      invalid = true;
    }

    const anyFloridaBlueContracts = existingContracts.some(function (
      _contract
    ) {
      return _contract.carrier.name
        .toString()
        .toLowerCase()
        .includes("florida blue");
    });

    if (anyFloridaBlueContracts) {
      //Florida Blue requires each agent to be gendered.  If an agent is not gendered, alert the user.
      if (!agentData.sex || agentData.sex === undefined) {
        sweetAlertMessage = "Florida Blue requires gender information.";
        invalid = true;
      }
      if (
        agentData.ssn === undefined ||
        agentData.ssn === null ||
        agentData.ssn.length < 9
      ) {
        sweetAlertMessage =
          "Florida Blue requires a 9 digit social security number.";
        invalid = true;
      }
      if (!agentData.birthDate) {
        sweetAlertMessage = "Florida Blue contracts require DOB.";
        invalid = true;
      }
    }

    const SCAN_Contracts = existingContracts.some(
      (contract) => contract.carrier.name === "SCAN"
    );

    if (
      SCAN_Contracts &&
      (agentData.licenseNumber === null ||
        agentData.licenseNumber === undefined ||
        agentData.licenseNumber === "")
    ) {
      sweetAlertMessage = "License number is required";
      invalid = true;
    }

    if (SCAN_Contracts && agentData.licenseNumber === undefined) {
      invalid = true;
    }

    if (!invalid) {
      setOpenValidationModal(true);
    } else {
      swalWithDialogAdjustment.fire({
        title: "Oops!",
        text: sweetAlertMessage,
        icon: "error",
      });
    }
  };
  const bDay = agent.birthDate ? agent.birthDate.split(" ")[0] : null;
  const email = contactStore.contacts?.email;
  const phone = contactStore.contacts?.phone;
  return (
    <>
      <Dialog
        open={openRequestForm}
        TransitionComponent={Transition}
        onClose={clearContractModal}
        maxWidth="sm"
        fullWidth
        fullScreen={mQuery}
      >
        <CommonDialogTitle
          title="Contract Request"
          close={clearContractModal}
        />
        <DialogContent
          style={{ padding: 10, paddingBottom: 30 }}
          dividers
          className={classes.scrollBar}
        >
          <form
            noValidate
            inputprops={{
              inputProps: { autocomplete: "new-password" },
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={6} style={{ marginBottom: 10 }}>
                <Typography variant="h6">
                  <b>Agent Info</b>
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{
                  border: "3px solid #eee",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Grid item xs={12} style={{ padding: 2 }}>
                  <Typography variant="subtitle2" style={{ color: "#D4AF37" }}>
                    * Information in this section will apply to ALL contracts
                    requested on this form
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems="flex-start"
                  style={{ padding: 2 }}
                >
                  <Grid item>
                    <Typography>Name: {agent.displayName}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems="flex-start"
                  style={{ padding: 2 }}
                >
                  <Grid item>
                    <Typography>
                      Phone:{" "}
                      {contactStore.contacts?.length === 0 ? null : phone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems="center"
                  style={{ padding: 2 }}
                >
                  <Tooltip
                    title={contactStore.contacts?.length === 0 ? null : email}
                    arrow
                  >
                    <Typography noWrap>
                      Email:{" "}
                      {contactStore.contacts?.length === 0 ? null : email}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ padding: 2 }}
                >
                  <Typography style={{ marginRight: 5 }}>
                    DOB: {bDay}
                  </Typography>
                  {!bDay && (
                    <Grid item xs={10}>
                      <CustomDatePicker
                        value={agentData.birthDate}
                        onChange={handleAgentDataChange}
                        name="birthDate"
                        label="DOB"
                        variant="standard"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6} style={{ padding: 2 }}>
                  <HtmlTooltip
                    title={!agent.ssn ? SocialNumberTooltipText : "SSN On File"}
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-ssn-input"
                      label="SSN"
                      name="ssn"
                      autoComplete="off"
                      onInput={(e) => numbersOnlyInput(e, 9)}
                      placeholder={
                        !agent.ssn ? SocialNumberTooltipText : "SSN On File"
                      }
                      disabled={agent.ssn && true}
                      fullWidth
                      type={toggleHide ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setToggleHide(!toggleHide)}
                              edge="end"
                              size="small"
                              style={{ color: "inherit", marginRight: 10 }}
                            >
                              {toggleHide ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={agentData.ssn}
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.ssn !== undefined}
                      helperText={agentValidationErrors.ssn}
                    />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={6} style={{ padding: 2 }}>
                  <HtmlTooltip
                    title={GenderTooltipText}
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-sex-input"
                      label="Gender"
                      name="sex"
                      disabled={agent.sex && true}
                      fullWidth
                      select
                      value={agentData.sex}
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.sex !== undefined}
                      helperText={agentValidationErrors.sex}
                    >
                      <MenuItem key="Female" value="FEMALE">
                        Female
                      </MenuItem>
                      <MenuItem key="Male" value="MALE">
                        Male
                      </MenuItem>
                    </TextField>
                  </HtmlTooltip>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="flex-end"
                  style={{ padding: 2 }}
                >
                  <HtmlTooltip
                    title={ResidentStateTooltipText}
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-residentstate-input"
                      label="Resident State"
                      name="residentState"
                      fullWidth
                      select
                      value={agentData.residentState}
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.residentState !== undefined}
                      helperText={agentValidationErrors.residentState}
                    >
                      {unitedStates?.map((state) => (
                        <MenuItem key={state.name} value={state.code}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={6} style={{ padding: 2 }}>
                  <HtmlTooltip
                    title={
                      !agent.licenseNumber
                        ? LicenseNumberTooltipText
                        : "License Number On File"
                    }
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-licenseNumber-input"
                      label={
                        !agent.licenseNumber
                          ? "License"
                          : "License Number On File"
                      }
                      name="licenseNumber"
                      autoComplete="new-password"
                      placeholder={
                        !agent.licenseNumber
                          ? LicenseNumberTooltipText
                          : "License Number On File"
                      }
                      disabled={agent.licenseNumber}
                      fullWidth
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.licenseNumber !== undefined}
                      helperText={agentValidationErrors.licenseNumber}
                      multiline
                    />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={12} style={{ padding: 2 }}>
                  <HtmlTooltip
                    title={!agent.npn ? NpnTooltipText : "NPN On File"}
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-npn-input"
                      label={!agent.npn ? "NPN" : "NPN On File"}
                      name="npn"
                      onInput={(e) => numbersOnlyInput(e)}
                      autoComplete="new-password"
                      placeholder={!agent.npn ? NpnTooltipText : "NPN On File"}
                      disabled={agent.npn && true}
                      fullWidth
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.npn !== undefined}
                      helperText={agentValidationErrors.npn}
                    />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={12} style={{ padding: 2 }}>
                  <Typography component="label">
                    Contracting As Corp?
                  </Typography>
                  <Checkbox
                    id="filled-contractingAsCorpValue-input"
                    name="ContractingAsCorp"
                    checked={corpfieldsShown}
                    onChange={handleInputChange}
                    color="primary"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={!corpfieldsShown}
                  style={{ color: "#D4AF37", padding: 2 }}
                >
                  <Typography variant="caption">
                    When checked, ALL contracts requested in this form will be
                    requested as a corporation
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  hidden={!corpfieldsShown}
                  style={{ padding: 2 }}
                >
                  <TextField
                    variant="standard"
                    id="agentData-corpName-input"
                    label="Corp Name"
                    name="corpName"
                    autoComplete="new-password"
                    fullWidth
                    required={corpfieldsShown}
                    value={agentData.corpName}
                    onChange={(e) => handleAgentDataChange(e)}
                    error={agentValidationErrors.corpName !== undefined}
                    helperText={agentValidationErrors.corpName}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  hidden={!corpfieldsShown}
                  style={{ padding: 2 }}
                >
                  <TextField
                    variant="standard"
                    id="filled-corpNPN-input"
                    label="Corp NPN"
                    name="corpNPN"
                    autoComplete="new-password"
                    fullWidth
                    required={corpfieldsShown}
                    value={agentData.corpNPN}
                    onChange={(e) => handleAgentDataChange(e)}
                    error={agentValidationErrors.corpNPN !== undefined}
                    helperText={agentValidationErrors.corpNPN}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={!corpfieldsShown}
                  style={{ padding: 2 }}
                >
                  <HtmlTooltip
                    title={TINTooltipText}
                    placement="top"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-corpTIN-input"
                      label="TIN"
                      name="corpTIN"
                      autoComplete="new-password"
                      placeholder={TINTooltipText}
                      fullWidth
                      size="small"
                      onInput={(e) => numbersOnlyInput(e, 12)}
                      value={agentData.corpTIN}
                      onChange={(e) => handleAgentDataChange(e)}
                      error={agentValidationErrors.corpTIN !== undefined}
                      helperText={agentValidationErrors.corpTIN}
                    />
                  </HtmlTooltip>
                </Grid>
              </Grid>
              <Grid
                item
                container
                id="contractChips"
                style={{
                  marginTop: 10,
                  display: [existingContracts.length === 0 ? "none" : "block"],
                  borderRadius: "10px",
                }}
              >
                <Grid item id="contractsToAdd" style={{ padding: 2 }}>
                  Requested Contracts:
                </Grid>
                <Grid item id="contractsToAddLabel" style={{ padding: 2 }}>
                  {existingContracts?.map((chipData, chipIndex) => (
                    <Chip
                      key={`${chipData.carrier.name}-${chipIndex}`}
                      label={chipData.carrier.name}
                      onClick={() => handleContractClicked(chipIndex)}
                      onDelete={() => handleContractDelete(chipIndex)}
                      color="primary"
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ padding: 2 }}>
                <Typography variant="h6">
                  <b>Add Contract</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                style={{ padding: 2 }}
              >
                <Grid item>
                  <Typography variant="body2">Carrier not listed?</Typography>
                </Grid>
                <Checkbox
                  id="filled-unlistedCarrier-input"
                  name="unlistedCarrier"
                  checked={unlistedCarrier}
                  onChange={(e) => setUnlistedCarrier(e.target.checked)}
                  color="primary"
                  size="small"
                />
              </Grid>
              <Grid item container>
                <Grid item xs={6} style={{ padding: 2 }}>
                  {!unlistedCarrier ? (
                    <TextField
                      variant="standard"
                      id="filled-carrier-input"
                      label="Carrier"
                      name="carrier"
                      fullWidth
                      required
                      select
                      size="small"
                      value={contract.carrier || ""}
                      onChange={handleContractChange}
                      error={
                        contractValidationErrors.carrier !== undefined &&
                        !contract.carrier
                      }
                      helperText={
                        !contract.carrier && contractValidationErrors.carrier
                      }
                    >
                      {carrierOptionsArray?.map((carrier) => (
                        <MenuItem key={carrier.id} value={carrier}>
                          {carrier.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      variant="standard"
                      value={contract.carrier?.name || ""}
                      name="carrier"
                      label="Enter Carrier"
                      onChange={unlistedCarrierChange}
                      size="small"
                      required
                      fullWidth
                      error={
                        contractValidationErrors.carrier !== undefined &&
                        !contract.carrier
                      }
                      helperText={
                        contractValidationErrors.carrier !== undefined &&
                        !contract.carrier &&
                        "Please enter a carrier."
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={6} style={{ padding: 2 }}>
                  <HtmlTooltip
                    title={StatesRequestedTooltipText}
                    placement="right"
                    interactive="true"
                    TransitionComponent={Zoom}
                  >
                    <TextField
                      variant="standard"
                      id="filled-carrier-input"
                      label="States Requested"
                      name="statesRequested"
                      fullWidth
                      inputprops={{
                        inputProps: {
                          maxLength: 60,
                          autocomplete: "new-password",
                        },
                      }}
                      required
                      size="small"
                      value={contract.statesRequested || ""}
                      onChange={handleContractChange}
                      error={
                        contractValidationErrors.statesRequested !==
                          undefined && !contract.statesRequested
                      }
                      helperText={
                        !contract.statesRequested &&
                        contractValidationErrors.statesRequested
                      }
                    />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={12} style={{ margin: 10 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item>
                      {Object.keys(contract).length > 0 ? (
                        <Button
                          size="small"
                          onClick={resetContractPanel}
                          color="secondary"
                          variant="contained"
                        >
                          Clear Contract
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        onClick={handleAddContract}
                        variant="contained"
                        color="primary"
                      >
                        {contractChipIndex == null
                          ? "Add Contract"
                          : "Update Contract"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: 30,
                  padding: 2,
                }}
              >
                <TextField
                  variant="standard"
                  multiline
                  id="filled-notes-input"
                  label={<Typography>Notes</Typography>}
                  name="notesValue"
                  fullWidth
                  size="small"
                  value={notesValue}
                  onChange={handleNotesChange}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item style={{ padding: 2 }}>
              <Button
                size="small"
                variant="contained"
                onClick={clearContractModal}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item style={{ padding: 2 }}>
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmit}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ValidateModal
        existingContracts={existingContracts}
        notesValue={notesValue}
        openValidationModal={openValidationModal}
        setOpenValidationModal={setOpenValidationModal}
        agentData={agentData}
        handleCancelSubmit={handleCancelSubmit}
        agentInfoSubmitted={agentInfoSubmitted}
        setAgentInfoSubmitted={setAgentInfoSubmitted}
        sendContractRequest={sendContractRequest}
        clearContractModal={clearContractModal}
        swalWithDialogAdjustment={swalWithDialogAdjustment}
        mQuery={mQuery}
        updateFromContractRequest={updateFromContractRequest}
        agentId={agentId}
        isAsCorp={corpfieldsShown}
        agentToh={agentToh}
        email={email}
        phone={phone}
        agentStore={agentStore}
      />
    </>
  );
});
export default ContractRequestModal;
