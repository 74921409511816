import React from "react";
import { Collapse, Grid, IconButton, Link, Typography } from "@mui/material";
import { Close, Info } from "@mui/icons-material";

const PrincipalMessage = (props) => {
  const { showPrincipalMessage, setShowPrincipalMessage } = props;
  return (
    <>
      <Collapse
        in={showPrincipalMessage}
        style={{ position: "relative", zIndex: 3 }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "10px 24px",
            position: "relative",
            background: "#D4AF37",
            color: "black",
          }}
        >
          <Grid item align="center">
            <Typography variant="caption">
              Our system has you registered as an agency principal, because of
              this contract requests should be made through the{" "}
              <Link
                href="https://theagencydashboard.com"
                target="_blank"
                rel="noreferrer noopener"
                color="primary"
              >
                Agency Dashboard
              </Link>
              . Reach out to your upline or Berwick agent support for concerns
              or questions.
            </Typography>
          </Grid>
          <IconButton
            size="small"
            style={{ position: "absolute", bottom: 2, right: 2 }}
            onClick={() => setShowPrincipalMessage(false)}
            color="inherit"
          >
            <Close />
          </IconButton>
        </Grid>
      </Collapse>
      <IconButton
        style={{
          position: "absolute",
          left: 0,
          top: 60,
          zIndex: 2,
          color: "#D4AF37",
        }}
        onClick={() => setShowPrincipalMessage(true)}
      >
        <Info />
      </IconButton>
    </>
  );
};
export default PrincipalMessage;
