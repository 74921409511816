import client from "./client";
import * as queries from "./GraphQl/queries";
import * as queriesNew from "./GraphQl/queries-new";

export const refreshGenericUserLoginGql = async (username, refreshToken) => {
  return client.query({
    query: queriesNew.refreshGenericLogin,
    variables: {
      username,
      refreshToken,
    },
  });
};

export const getCRMOwners = async () => {
  return client.query({
    query: queries.getCRMOwners,
  });
};

export const getCarrierNameListGql = async () => {
  return client.query({
    query: queriesNew.getCarrierNameList,
  });
};

export const searchLeadsAndAgents = async (query, type, limit, crmOwner) => {
  return client.watchQuery({
    query: queries.searchLeadsAndAgents,
    variables: {
      query,
      type,
      limit,
      crmOwner,
    },
  });
};

export const agentProductionPortal = async (agentId) => {
  return client.query({
    query: queriesNew.agentProductionPortal,
    variables: {
      agentId,
    },
  });
};

export const expandedAgentProductionPortal = async (agentId) => {
  return client.query({
    query: queriesNew.expandedAgentProductionPortal,
    variables: {
      agentId,
    },
  });
};

export const getTicketsByIdForContractNotes = async (id) => {
  return client.query({
    query: queries.getTicketsByIdForContractNotes,
    variables: {
      id,
    },
  });
};
export const articlesByTicketId = async (id) => {
  return client.query({
    query: queries.articlesByTicketId,
    variables: {
      id,
    },
  });
};
export const sendEmailApiGql = async (
  from,
  to,
  cc,
  bcc,
  subject,
  body,
  attachments,
  save
) => {
  return client.mutate({
    mutation: queries.sendEmailApi,
    variables: {
      from,
      to,
      subject,
      body,
    },
  });
};

export const createLeadGql = async (info) => {
  return client.mutate({
    mutation: queries.createLead,
    variables: {
      info,
    },
  });
};

export const updateLeadGql = async (info, id, contactId) => {
  return client.mutate({
    mutation: queries.updateLead,
    variables: {
      info,
      id,
      contactId,
    },
  });
};

export const updateAgentGql = async (info, id, contactId) => {
  return client.mutate({
    mutation: queries.updateAgent,
    variables: {
      info,
      id,
      contactId,
    },
  });
};

export const getLeadSources = async () => {
  return client.query({
    query: queries.getLeadSources,
  });
};

export const createNoteGql = async (info) => {
  return client.mutate({
    mutation: queries.createNoteV2,
    variables: {
      info,
    },
  });
};

export const createNoteCommentGql = async (noteId, info) => {
  return client.mutate({
    mutation: queries.createNoteComment,
    variables: { noteId, info },
  });
};

export const saveContactAfterEditGql = async (info) => {
  return client.mutate({
    mutation: queries.saveContactAfterEdit,
    variables: {
      info,
    },
  });
};

export const agentHierOnClickGql = async (id) => {
  return client.mutate({
    mutation: queries.agent,
    variables: {
      id,
    },
  });
};

export const getReportDataByAgencyIdGql = async (agencyId, year) => {
  return client.query({
    query: queries.reportDataByAgencyId,
    variables: {
      agencyId,
      year,
    },
  });
};

export const getOwnerAgenciesGQL = async () => {
  return client.query({
    query: queriesNew.ownerAgencies,
  });
};

export const createContactGql = async (info) => {
  return client.mutate({
    mutation: queries.createContact,
    variables: {
      info,
    },
  });
};

export const reportByOwnerIdAndYearGql = async (id, yr) => {
  return client.query({
    query: queries.reportByOwnerIdAndYear,
    variables: {
      id,
      yr,
    },
  });
};

export const createLeadContractRequestGql = async (info) => {
  return client.mutate({
    mutation: queries.createLeadContractRequest,
    variables: {
      info,
    },
  });
};

export const eodReportGql = async (date) => {
  return client.query({
    query: queries.eodReport,
    variables: {
      date,
    },
  });
};

export const contractRequestsByAgentIdGql = async (agentId) => {
  return client.query({
    query: queries.contractRequestsByAgentId,
    variables: {
      agentId,
    },
  });
};

export const ytdRecruitmentReportGql = async () => {
  return client.query({
    query: queriesNew.ytdRecruitmentReport,
  });
};

export const getFileUrl = async (fileId) => {
  const params = { id: fileId };
  return await client.query({
    query: queriesNew.getSignedUrl,
    variables: params,
  });
};

export const getLanguages = async () => {
  return await client.query({
    query: queriesNew.getLanguages,
  });
};

export const getCarriers = async () => {
  return await client.query({
    query: queriesNew.getCarriers,
  });
};

export const agencyContractStatusReport = async (
  agencyId,
  carrierId,
  status,
  state
) => {
  return await client.query({
    query: queriesNew.agencyContractStatusReport,
    variables: { agencyId, carrierId, status, state },
  });
};

export const resetAgentLoginDate = async (agentId) => {
  return await client.query({
    query: queriesNew.resetAgentLoginDate,
    variables: { agentId },
  });
};

export const certsPerAgent = async (agentId) => {
  return await client.query({
    query: queriesNew.certsPerAgent,
    variables: { agentId },
  });
};

export const contractStatusTotals = async (
  carrierId,
  contractStatus,
  agentOwnerId,
  state
) => {
  return await client.query({
    query: queriesNew.contractStatusTotals,
    variables: { carrierId, contractStatus, agentOwnerId, state },
  });
};

export const contractStatusExcelContents = async (
  carrierId,
  contractStatus,
  agentOwnerId,
  state
) => {
  return await client.query({
    query: queriesNew.contractStatusExcelContents,
    variables: { carrierId, contractStatus, agentOwnerId, state },
  });
};

export const agencyProductionByCarrier = async (
  carrierId,
  salesYear,
  agentOwnerId,
  state
) => {
  return await client.query({
    query: queriesNew.agencyProductionByCarrier,
    variables: { carrierId, salesYear, agentOwnerId, state },
  });
};

export const sendContractRequestGql = async (info) => {
  return client.mutate({
    mutation: queriesNew.sendContractRequest,
    variables: {
      info,
    },
  });
};

export const getCurrentProductionYear = async () => {
  let res = await client.query({
    query: queriesNew.getCurrentProductionYear,
  });

  return res.data.getCurrentProductionYear.currentproduction;
};

export const createContractRequest = async (input) => {
  return client.mutate({
    mutation: queriesNew.createContractRequest,
    variables: {
      input,
    },
  });
};

export const getStates = async () => {
  return client.query({
    query: queriesNew.getStates,
  });
};

export const checkAgentNpn = async (agentId, npn) => {
  const { data } = await client.query({
    query: queriesNew.checkAgentNpn,
    variables: { agentId, npn },
  });
  return data.checkAgentNpn;
};

export const updateAgentPassword = async (options) => {
  return await client.mutate({
    mutation: queriesNew.updateAgentPassword,
    variables: { options },
  });
};

export const sendAwayEmailAws = async (input) => {
  return await client.mutate({
    mutation: queriesNew.sendAwayEmailAws,
    variables: { input },
  });
};

export const cognitoForgotPassword = async (input) => {
  return await client.mutate({
    mutation: queriesNew.cognitoForgotPassword,
    variables: input,
  });
};

export const cognitoConfirmPassword = async (input) => {
  return await client.mutate({
    mutation: queriesNew.cognitoConfirmPassword,
    variables: input,
  });
};

export const isAgentPrincipal = async (agentId) => {
  return await client.query({
    query: queriesNew.isAgentPrincipal,
    variables: { agentId },
  });
};

export const updateLastLogin = async (id) => {
  return await client.mutate({
    mutation: queriesNew.updateLastLogin,
    variables: { id },
  });
};
//Onboarding
export const findCode = async (code) => {
  return await client.query({
    query: queriesNew.findCode,
    variables: { code },
  });
};
export const findNpn = async (npn) => {
  return await client.query({
    query: queriesNew.findNpn,
    variables: { npn },
  });
};
export const registerOnboardLead = async (options) => {
  const results = await client.mutate({
    mutation: queriesNew.registerOnboardLead,
    variables: { options },
  });
  return results.data.registerOnboardLead;
};
export const ocrByOnboardId = async (onboardId) => {
  return await client.query({
    query: queriesNew.ocrByOnboardId,
    variables: { onboardId },
  });
};

export const updateOnboardLead = async (input) => {
  return await client.mutate({
    mutation: queriesNew.updateOnboardLead,
    variables: { input },
  });
};

export const insertOcr = async (input) => {
  return await client.mutate({
    mutation: queriesNew.insertOcr,
    variables: { input },
  });
};
//End Onboarding

export const getCognitoUserAgent = async (options) => {
  const results = await client.mutate({
    mutation: queriesNew.getCognitoUserAgent,
    variables: { options },
  });
  return results.data.getCognitoUserAgent;
};

export const isAgent = async (id) => {
  const results = await client.mutate({
    mutation: queriesNew.isAgent,
    variables: { id },
  });
  return results.data.isAgent;
};
export const getCalc = async (eligibleDate, enrollmentDate) => {
  return await client.query({
    query: queriesNew.getCalc,
    variables: { eligibleDate, enrollmentDate },
  });
};
