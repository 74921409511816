import React from "react";
import { ExpandMore, Check } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";

const CustomDivider = () => {
  return <Divider style={{ background: "lightgrey", width: "100%" }} />;
};

const CarrierHierarchyDetails = (props) => {
  const { classes, item } = props;
  return (
    <Accordion className={classes.carrier}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.expLabel}>
          <b>Carrier Info</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          background: "white",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText}>
              Carrier:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.carrierInfoText}
              align="right"
            >{`${item.carrierName}`}</Typography>
          </Grid>
          <CustomDivider />
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText}>Level: </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.carrierInfoText}
              align="right"
            >{`${item.agentLevel}`}</Typography>
          </Grid>
          <CustomDivider />
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText}>
              Corporation:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText} align="right">
              {item.isCorporation === true ? "Yes" : "No"}
            </Typography>
          </Grid>
          <CustomDivider />
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText}>
              Company Name:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.carrierInfoText}
              align="right"
            >{`${item.companyName}`}</Typography>
          </Grid>
          <CustomDivider />
          <Grid item xs={6}>
            <Typography className={classes.carrierInfoText}>
              Tax Id:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.carrierInfoText}
              align="right"
            >{`${item.taxId}`}</Typography>
          </Grid>
          {item.overrideAgreement === true && (
            <>
              <CustomDivider />
              <Grid item xs={6}>
                <Typography className={classes.carrierInfoText}>
                  Override Agreement on File:
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Typography className={classes.carrierInfoText} align="right">
                    Yes{" "}
                  </Typography>
                </Grid>
                <Check
                  style={{
                    color: "#4caf50",
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default CarrierHierarchyDetails;
